<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getDashboard } from '@/services/dashboard.service'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  async mounted() {
    this.reportData = await getDashboard()
    this.ticketTypeSeries = Object.values(this.reportData.pie)
    this.ticketTypeLabels = Object.keys(this.reportData.pie)
    this.chartOptions = { labels: this.ticketTypeLabels }

    this.revenueSeries = [
      {
        name: "GTQ",
        type: "column",
        data: Object.values(this.reportData.histogram)
      }
    ]

    this.revenueChartOptions = { labels: Object.keys(this.reportData.histogram)}
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      ticketTypeSeries: [],
      ticketTypeLabels: [],
      revenueSeries: [],
      revenueChartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
      },
      chartOptions: {
        chart: {
          height: 230,
          type: "donut"
        },

        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"]
      },
      reportData: {
        tickets: [
          {count: 0}
        ],
        sales: [
          {total: 0}
        ],
        averages: [
          {average: 0}
        ]
      }
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">Tickets Emitidos Hoy</p>
                    <h4 class="mb-0">{{reportData.tickets[0].count}}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`ri-stack-line font-size-24`"></i>
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">Total Facturado Hoy</p>
                    <h4 class="mb-0">Q {{parseFloat(reportData.sales[0].total).toFixed(2)}}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`ri-stack-line font-size-24`"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">Valor Promedio Por Factura</p>
                    <h4 class="mb-0">Q {{parseFloat(reportData.averages[0].average).toFixed(2)}}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`ri-stack-line font-size-24`"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button type="button" class="btn btn-sm btn-light active">Semanal</button>
              </div>
            </div>
            <h4 class="card-title mb-4">Histograma De Ventas</h4>
            <div>
              <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
              <apexchart
                  class="apex-charts"
                  height="280"
                  dir="ltr"
                  :series="revenueSeries"
                  :options="revenueChartOptions"
              ></apexchart>
            </div>
          </div>

        </div>


      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Tipos de ticket emitidos hoy</h4>

            <div id="donut-chart" class="apex-charts"></div>
            <apexchart
                class="apex-charts"
                height="230"
                dir="ltr"
                :labels="ticketTypeLabels"
                :series="ticketTypeSeries"
                :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>