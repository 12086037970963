const axios = require('axios')
const userService = require("@/services/user.service");
const api_base = process.env.VUE_APP_API_BASE_URL

const getDashboard = async () => {
    const me = await userService.me()
    const url = `${api_base}/v1/dashboard/${me._id}`

    const report = await axios.get(url)
        .catch(() => {
            console.log('ERROR GETTING DASHBOARD REPORT')
            return {
                data: {}
            }
        })

    return report.data
}

module.exports = {
    getDashboard
}